import React from "react"
import Layout from "../components/Layout"

import ReactPlayer from "react-player"

const greta = () => {
  return (
    <Layout>
      <section style={{ position: "relative" }}></section>
      <div
        style={{
          display: "flex",
          flexFlow: "row",
          backgroundColor: "#1d242a",
          padding: "40px",
        }}
      >
        <div
          style={{
            backgroundColor: "black",
            width: "50%",
            height: "700px",
            justifyContent: "center",
          }}
        >
          <ReactPlayer
            url="https://www.youtube.com/watch?v=LRqPnEmruvY"
            playing={true}
            loop={true}
            width="100%"
            height="100%"
            light="/greta01.png"
          />
        </div>
        <div
          style={{
            backgroundColor: "#1d242a",
            width: "50%",
            height: "700px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p
            style={{
              paddingTop: "50px",
              color: "white",
              fontFamily: "Inconsolata",
              fontSize: "25px",
              margin: "40px",
              textAlign: "center",
            }}
          >
            <h2>Greta comes later:</h2> the bachelor project of students from
            the University of Bremen and the University of the Arts Bremen from
            2019. In the point and click adventure Greta has to save her pet
            duck Ori, which unfortunately gets lost in a train. On the train
            Greta has to solve puzzles by interacting with other passengers.{" "}
            <br />
            For this project I created the main animations with the 2D animation
            program Spine. I was also involved in the character design and
            supported the programmers during their work with my knowledge.
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default greta
